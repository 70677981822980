.max-input {
  text-align: center;
  height: 2.2rem;
  font-weight: 100;
  width: 2.9rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: black;
}

.min-input {
  text-align: center;
  height: 2.2rem;
  font-weight: 100;
  width: 2.9rem;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: black;
}

.input-status {
  height: 28px !important;
  font-size: 11px;
  /* margin-right: 5px; */
  vertical-align: middle;
  background-position-x: 2.5rem;
  border-radius: 5px;
}

.main input {
  color-scheme: light !important;
}

.main tbody tr td {
  font-size: 0.7rem !important;

  vertical-align: middle !important;
}

.thead-buttons {
  font-size: 10px !important;
  padding: 10px 20px;
  margin-right: 2px;
  text-align: center !important;
  vertical-align: middle !important;
  border-radius: 8px;
  font-size: 12px;
  box-shadow: rgba(133, 166, 248, 0.464) 1.95px 1.95px 2.6px;
}

.thead-buttons-active {
  background-color: #48b0db !important;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.627) 0px 2px 4px 0px inset;
}
.thead-buttons:hover {
  background-color: #48b0db !important;
  color: #fff !important;
  transition: 0.5s;
}

.search-input {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
  outline: none;
  border: none;
  font-size: 12px;
  background-color: transparent;
}

.search-input-container {
  border: 1px solid grey;
  border-radius: 8px;
  height: 40px;
}

.custom-header-title {
  font-size: 10px;
  font-weight: lighter;
  /* margin-bottom: 4px; */
  /* border-radius: 2px; */
  border-radius: 6px;
  /* margin: 10px; */
  display: flex;
  padding: 0px;
  margin: 5px;
  align-items: center;
  justify-content: center;
  width: 100%;

  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.custom-header-title:hover {
  background-color: rgb(1, 215, 143);
}

.custom-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 2px 0px 2px;
  /* border: 1px solid black; */
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
}

.custom-header-icon {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  margin: 6px;

  /* padding: 5px; */
  /* border: 2px solid black; */
  display: block;
  max-width: 100%;
  /* vertical-align: middle; */
}

.custom-header-input {
  width: 6rem;
  white-space: nowrap;
}

.backgroundBasic {
  color: white;
  background-color: #1e1e1e;
}

.limit {
  width: 6rem;
  z-index: 999;
}

.quick-filter {
  width: 9rem;
  z-index: 999;
}

.delete-icon {
  cursor: pointer;
  color: #d20000 !important;
}

/* 
body {
  overflow: auto !important;
} */
