.ml-8 {
  margin-left: 8px;
}

/* Gloabal Card Form Styling */

.sub-entry-event-one,
.sub-entry-event-staff-one,
.sub-entry-venue-one,
.sub-entry-production-one,
.sub-entry-transportation-one,
.sub-entry-payment-one,
.sub-entry-contract,
.sub-entry-contract-overview {
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  min-height: 30rem;
  border-top: none;
}

td,
th {
  font-size: 9px !important;
  padding: 1px !important;
}

.event-staff-table {
  height: 100px !important;
}

/* Add - Reset - Submit Buttons */

.payment_input {
  background-color: transparent;
  border: none;
  height: 24px;
  max-width: 60px;
  font-weight: 600;
  border-bottom: 1px solid #000000;
  margin-right: 20px;
}

.payment_input:focus,
.payment_input:focus-visible {
  outline: none;
  box-shadow: none !important;
}

/*Cover tag Span */

.filter_head .default-placeholder {
  padding: 0px !important;
}

.filter_head {
  margin-top: 5px;
}

.filter_head input {
  width: calc(100vw - 94vw) !important;
  border: 1px solid rgb(229, 229, 234);
  border-radius: 6px;
  color: #00000080;
  outline: none;
}

.filter_head input:focus-within {
  border-color: #3498ff;
}

.filter_head .date-picker-app-wrapper {
  width: calc(100vw - 94vw) !important;
  overflow: hidden;
}

.rs-custom-input {
  max-width: 45px !important;
  font-size: 8px !important;
  padding: 2px !important;
  height: 24px !important;
}
