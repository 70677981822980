nav {
  margin: 0;
  padding: 0.5rem !important;
  z-index: 1;
}

.dropdown-link {
  padding: 3px 10px;
  white-space: nowrap;
  text-decoration: none;
}

.last-word {
  color: rgb(101, 174, 215) !important;
}

.notification-wrapper,
.owner-dropdown,
.nav-link {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex !important;
  /* height: 48px; */
  justify-content: center;
  /* line-height: 1; */
  list-style: none;
  /* overflow: hidden; */
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  position: relative;
  padding: 5px !important;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 12px !important;
}

.image-wrapper {
  padding: 1px 10px;
  margin-right: 15px;
  margin-left: -10px;
  border-radius: 4px;
  align-items: center;
  appearance: none;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(69, 72, 89, 0.5) 0 -3px 0 inset;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.image-wrapper-burger {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1px 25px;
  width: auto;
  border-radius: 4px;
  align-items: center;
  appearance: none;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(69, 72, 89, 0.5) 0 -3px 0 inset;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

/* .image-wrapper:hover,
.image-wrapper-burger:hover {
  opacity: 0.8;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
} */

.logo-image {
  width: 125px;
  padding: 1px;
  padding-bottom: 7px;
}

.nav-item-contracts {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5aff81 0,
    #00b850 100%
  ) !important;
}

.nav-item-support-ticket {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #ff9292 0,
    #ff5454 100%
  ) !important;
}

.nav-item-support {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #c296ff 0,
    #873dee 100%
  ) !important;
}

.nav-item-logout {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #ffd38b 0,
    #ff9854 100%
  ) !important;
}

.notification-wrapper {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #ff85fb 0,
    #d210be 100%
  ) !important;
}

.notification-wrapper.active {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #85fdff 0,
    #00b2b8 100%
  ) !important;
}

.owner-dropdown {
  padding: 6px 10px !important;
}

.dropdown-menu {
  font-size: 1rem !important;
}

.notification-wrapper:focus,
.owner-dropdown:focus,
.nav-link:focus {
  /* box-shadow: #e0e4ffa7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #cad1ff72 0 -3px 0 inset; */
  text-decoration: none !important;
}

.notification-wrapper:hover,
.owner-dropdown:hover,
.nav-link:hover,
.image-wrapper:hover,
.image-wrapper-burger:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e7eaff85 0 -3px 0 inset;
  transform: translateY(-2px);
  text-decoration: none;
}

.notification-wrapper:active,
.owner-dropdown:active,
.nav-link:active {
  box-shadow: #d6dbff7e 0 3px 7px inset;
  transform: translateY(2px);
  text-decoration: none !important;
}

.nav-item {
  margin: 0 10px 0 0 !important;
}
