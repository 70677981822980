* {
  box-sizing: border-box;
  text-decoration: none;
  font-family: "source-sans-pro", sans-serif;
}

.loader {
  /* the colors */
  --c1: #ffffff;
  --c2: #ffffff;
  --c3: #4299fd;
  --c4: #117cff;
  /**/

  width: 100px; /* control the size */
  aspect-ratio: 7/5;
  --_g: no-repeat radial-gradient(#000 68%, #0000 71%);
  -webkit-mask: var(--_g), var(--_g), var(--_g);
  -webkit-mask-size: 30% 45%;
  background: conic-gradient(var(--c1) 50%, var(--c2) 0) no-repeat,
    conic-gradient(var(--c3) 50%, var(--c4) 0) no-repeat;
  background-size: 200% 50%;
  animation: back 3s infinite steps(1), load 1.5s infinite linear;
}

.number-input-drawer::-webkit-outer-spin-button,
.number-input-drawer::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  /* -webkit-appearance: none; */
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.number-input-drawer[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* opacity: 0.7; */
  margin-left: -14px;
}

@keyframes load {
  0% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%;
  }
  12.5% {
    -webkit-mask-position: 0% 50%, 50% 0%, 100% 0%;
  }
  25% {
    -webkit-mask-position: 0% 100%, 50% 50%, 100% 0%;
  }
  37.5% {
    -webkit-mask-position: 0% 100%, 50% 100%, 100% 50%;
  }
  50% {
    -webkit-mask-position: 0% 100%, 50% 100%, 100% 100%;
  }
  62.5% {
    -webkit-mask-position: 0% 50%, 50% 100%, 100% 100%;
  }
  75% {
    -webkit-mask-position: 0% 0%, 50% 50%, 100% 100%;
  }
  87.5% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 50%;
  }
  100% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%;
  }
}
@keyframes back {
  0%,
  100% {
    background-position: 0% 0%, 0% 100%;
  }
  25% {
    background-position: 100% 0%, 0% 100%;
  }
  50% {
    background-position: 100% 0%, 100% 100%;
  }
  75% {
    background-position: 0% 0%, 100% 100%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  letter-spacing: 0px !important;
}

.table th.up {
  background-image: url("./Images/up_arrow.png");
  background-size: 15px;
}
.table th.down {
  background-image: url("./Images/down_arrow.png");
  background-size: 15px;
}
.table th.default {
  background-image: url("./Images/default.png");
  background-size: 15px;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
  padding: 2px !important;
}

.arrow-disclosure {
  margin-left: -6px;
}
.arrow-disclosure-selected {
  background-color: rgb(115, 225, 188) !important;
  box-shadow: rgba(0, 0, 0, 0.378) 0px 2px 4px 0px inset !important;
}

.toggle-filter-chip-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  flex-wrap: wrap;
  white-space: nowrap;
  gap: 10px;
  margin-bottom: 5px;
}

.option-label {
  font-size: 13px;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.avatar-div {
  /* padding-right: 7px; */
  border: 2px solid white;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

/* Gloabal Card Form Styling */
.sub-entry-event-one,
.sub-entry-venue-one,
.sub-entry-production-one,
.sub-entry-payment-one,
.sub-entry-contract,
.sub-entry-stats {
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  min-height: 30rem;
  border-top: none;
}

.list-group-item {
  padding: 2px 0 0 5px !important;
}

td,
th {
  font-size: 9px !important;
  padding: 0px !important;
}
/* Glabal Card Form Styling */

.accordion-button::after {
  background-image: url("./.././styles/Images/white-down-arrow-icon.jpg") !important;
}

#li-error {
  background-color: rgba(255, 0, 0, 0.461);
}

#li-success {
  background-color: rgba(8, 255, 8, 0.311);
  font-weight: 900 !important;
}

/* Add - Reset - Submit Buttons */
.btn-success {
  border-radius: 4px !important;
}

.btn-danger-home {
  border-radius: 4px !important;
  padding: 6px 15px 6px 15px !important;
  margin: -1rem;
  font-size: 0.8vw !important;
  color: white !important;
  background-color: red !important;
}

.pointer {
  cursor: pointer;
}

.ant-modal-content {
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 0 !important;
  border-radius: 8px !important;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

.ant-modal-confirm-title {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
}

.ant-btn {
  font-size: 14px !important;
  height: 32px !important;
  padding: 4px 15px !important;
  border-radius: 6px !important;
}

select {
  border-radius: 4px !important;
  padding-left: 5px !important;
  height: 39px !important;
}

/* EDIT EMPLOYEE INPUT LABLES */
.n-label {
  display: inline-block;
  padding-top: 1px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  width: 100px;
}

.page-container.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
}

.login-form {
  margin-top: 40px !important;
}

.login-card {
  width: 443px;
}

.login-card .ant-input-affix-wrapper,
.login-card .form-button {
  height: 40px !important;
  padding: 0px 11px 10px 0px !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}

.ant-form-item-explain-error {
  font-weight: 400;
}
.login-card input {
  font-size: 15px;
}

.login-card img.icon {
  height: 13px;
  width: 13px;
}

.login-card .sub-title {
  font-size: 16px !important;
  text-align: left;
}

.login-card .login-form {
  margin-top: 65px !important;
}
.login-form .rounded {
  height: 57px !important;
  padding-top: 10px !important;
  font-size: 18px;
}

.login .logo-container img {
  margin-bottom: 0;
  width: 120px;
}

.other-page-container p {
  font-size: 18px !important;
  text-align: center;
}
.other-page-container p strong:hover {
  text-decoration: underline;
}

.login-card .forgot-password-text {
  font-size: 14px !important;
  text-align: right;
}
.login-card .ant-input-affix-wrapper {
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}

.login-card .ant-card-body {
  padding: 24px 47px !important;
}

.login-card .title {
  font-size: 25px;
  text-align: left;
  margin-top: 16px;
  letter-spacing: normal;
}

.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: "source-sans-pro", sans-serif;

  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}

.no-margin {
  margin: 0 !important;
}

.note {
  font-weight: 500;
  letter-spacing: normal;
  color: #42526eb2 !important;
}

.ant-input-prefix {
  margin-right: 20px !important;
}

.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item {
  width: 100%;
}

.ant-form-item-feedback-icon-error {
  display: flex;
}

.vimeo-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.7;
  transform: translate(-50%, -50%);
}

/* TEST */

/* Main Box */
.box {
  position: relative;
  border-radius: 10px;
  overflow: hidden; /*Limit gradient layer inside our card.*/
  display: flex;
}

/*Two Rotating Bpx */
.box::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #78cdff
  );
  animation: animate 4s linear infinite;
}

.box::after {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #0b9df2
  ); /*Use conic gradient to render the flowing styles */
  animation: animate 4s linear infinite; /*Make it move*/
  animation-delay: -2s; /*Prevent overlaps*/
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Cover tag Span */
.box span {
  position: absolute;
  inset: 5px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255);
  z-index: 1;
}

.box h5 {
  position: relative;
  z-index: 2;
  color: #000000;
  font-size: 1.5em;
}

.stats-rs-picker.rs-picker-toggle.rs-btn {
  display: flex !important;
}
.rs-picker-menu {
  min-width: 15vw !important;
}

.rs-picker-cascader-menu-column {
  min-width: 15vw !important;
  font-size: 11px !important;
}

.rs-cascade-warning {
  color: red !important;
}
.login-screen-wrapper {
  background-image: url("../../public/images/login-bg-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.table-input-amount {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  color: gray;
  font-size: 14px;
}

.table-amount-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
  /* height: 100%; */
}

.numeric-input {
  tab-indent: 8px !important;
  padding-left: 14px !important;
}

.ql-container.ql-snow {
  border: none !important;
}

[data-theme="dark"] {
  /* timePickerStyles.css */
  .rc-time-picker-panel-inner {
    background-color: #333 !important; /* Dark background color for the dropdown */
    color: #fff !important; /* Light text color for better visibility */
  }

  .rc-time-picker-panel-input {
    color: #fff; /* Light text color for the input */
    background-color: transparent; /* Light text color for the input */
  }

  .rc-time-picker-panel-select-option-selected {
    background-color: #555 !important; /* Darker background color for the selected option */
  }
}

/* TABLE CSS FOR VENUE, EVENT STAFF, TRANSPORTATION, PRODUCTION DETAILS */

#form-control-input {
  font-weight: 600 !important;
  font-size: 11px !important;
  /* padding: 0; */
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0) !important;
  /* text-align: center; */
}
#form-control-input-prod {
  font-weight: 600 !important;
  font-size: 11px !important;
  /* padding: 0; */
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0) !important;
  text-align: center;
}

#form-control-input-add-bev {
  font-weight: 600 !important;
  font-size: 11px !important;
  /* padding: 0; */
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0) !important;
  text-align: left;
}

.form-control {
  padding: 0 !important;
}

.form-control.custom {
  padding: 0.75rem 1rem 0.75rem 1rem !important;
  text-align: center;
}

#form-control-input-description {
  font-weight: 600 !important;
  font-size: 11px !important;
  color: black;
  padding: 10px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0) !important;
  text-align: center;
  width: 100%;
}

.td-style {
  text-align: center;
}

.td-style-trans {
  font-weight: 800 !important;
  padding: 5px !important;
  text-align: center;
}
/* 
td.td-style {
  padding: 4px !important;
} */

table {
  border-radius: 0 0 0.5rem 0.5rem !important;
  overflow: hidden;
}
.td-style td,
th {
  font-size: 0.8vw !important;
  text-align: center;
}

.prod-equip-type {
  text-align: center !important ;
}

thead td {
  text-align: center;
  margin: auto;
  vertical-align: middle;
}

.td-style input[type="number"],
#form-control-bev {
  max-width: 100%;
}

.td-style input[type="text"] {
  max-width: 100%;
}

#form-control-bev {
  display: block;
  font-weight: 600;
  padding: 0.75rem 1rem 0.75rem 1rem !important;
  text-align: center;
}

td:first-child {
  width: 170px !important;
  /* text-align: left; */
}

/* pricing-panel-wrapper */

#pricing-panel-wrapper .ant-collapse-content-box {
  padding: 0px;
}

#pricing-panel-wrapper .ant-collapse-header {
  border-radius: 6px;
  padding: 6px 40px 6px 8px;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  ) !important;
}

#pricing-panel-wrapper .ant-collapse-header[aria-expanded="true"] {
  border-radius: 6px 6px 0 0;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  ) !important;
}
#contract-main-table
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
  border: none;
}

#contract-main-table
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 1);
  background: transparent;
  cursor: pointer;
}

#contract-main-table .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 8px 0 2px;
}
